import jwt_decode from "jwt-decode";
const ID_TOKEN_KEY = "access_token";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const authorizeToken = () => { 
  const token = getToken();
  if (token) {
    const user = jwt_decode(token);
    // console.log(user);
    if(user) {
      if (Date.now() <= user.exp * 1000) {
        // console.log(user);
        return user;
      }
    }
  }
  return false;
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken, authorizeToken };
